export const initComoanyRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/companies",
          name: "company",
          component: () => import("@/pages/manage/companies/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/companies/:id",
          name: "company-id",
          component: () => import("@/pages/manage/companies/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/companies/:id/course-items",
          name: "company-branch",
          component: () =>
            import("@/pages/manage/companies/zones/branches/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/companies/:id/course-items/:branchId",
          name: "company-branch-id",
          component: () =>
            import("@/pages/manage/companies/zones/branches/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
