
import { Vue, Component } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import editor from "./editor";
import draggable from "vuedraggable";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {ComponentSeoItemListDataSource, ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import {required} from "@/cms-services/consts";
@Component({
  mixins: [editor],
  components: { ImageEditor, DataEditor, draggable },
})
export default class ResourceInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  dialog = false;
  dialog1 = false;
  loaded = false;
  $message: any;
  text = ""
  textType:any =  null;


  

  detectTextType(text: string){
    if (!text.trim()) return 0;

    try {
        const parsed = JSON.parse(text);
        if (Array.isArray(parsed) && parsed.every(item => typeof item === 'string')) {
            return 3;
        }
    } catch {
        // Not JSON, continue with other checks
    }

    if (text.includes(',')) {
        return 1;
    }

    if (/\r?\n/.test(text)) {
        return 2;
    }

    return 0;
  };

  test(){
    this.textType = this.detectTextType(this.text);
  }


  seoItemDataSource = new ComponentSeoItemListDataSource()

  createItemFields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Заголовок",
      },
      name: "keyword",
      validations: [required()],
    },
  ];


  get color1(){
    return this.textType == 1 ? "primary" : "default"
  }
  get color2(){
    return this.textType == 2 ? "primary" : "default"
  }
  get color3(){
    return this.textType == 3 ? "primary" : "default"
  }

  close(){
    this.text = "";
    this.dialog1 = false
  }

  createItemModel:any = {
    componentId:+this.dataSource?.id,
    keyword:""
  }

  async submit(){
    this.textType = this.detectTextType(this.text);

    await this.seoItemDataSource.addScope({
      componentId: this.dataSource.model.id,
      text: this.text,
      type: this.textType
    })

    this.textType = null;
    this.text = "";
    

    this.dialog1 = false;
    this.$message("Данные успешно добавлены")
  }

  async mounted(){
    this.seoItemDataSource.config.filter = JSON.stringify([
        new Filter("componentId", this.dataSource?.id)
    ])

    await this.seoItemDataSource.get();
    this.loaded = true;
  }
}
