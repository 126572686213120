export const initCourseRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/courses",
          name: "course",
          component: () => import("@/pages/manage/courses/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/courses/:id",
          name: "course-id",
          component: () => import("@/pages/manage/courses/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/courses/:id/course-items",
          name: "course-branch",
          component: () =>
            import("@/pages/manage/courses/zones/course-items/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/courses/:id/course-items/:branchId",
          name: "course-branch-id",
          component: () =>
            import("@/pages/manage/courses/zones/course-items/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
