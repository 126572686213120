import Vue from "vue";
import _ from "lodash";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import authModule from "@/store/modules/auth";
import userModule from "@/store/modules/user";

import { initCrmRoutes } from "@/router/objects/initCrmRoutes";
import { initBaseRoutes } from "@/router/base/initBaseRoutes";
import { initUserRoutes } from "@/router/objects/initUserRoutes";
import { initRoleRoutes } from "@/router/objects/initRoleRoutes";
import { initFormRoutes } from "@/router/components/initFormRoutes";
import { initPageRoutes } from "@/router/components/initPageRoutes";
import { initCaseRoutes } from "@/router/components/initCaseRoutes";
import { initNewsRoutes } from "@/router/components/initNewsRoutes";
import { initOrderRoutes } from "@/router/objects/initOrderRoutes";
import { initBrandRoutes } from "@/router/components/initBrandRoutes";
import { initEventRoutes } from "@/router/components/initEventRoutes";
import { initVideoRoutes } from "@/router/components/initVideoRoutes";
import { initRegionRoutes } from "@/router/objects/initRegionRoutes";
import { initResumeRoutes } from "@/router/objects/initResumeRoutes";
import { initReviewRoutes } from "@/router/components/initReviewRoute";
import { initDoctorRoutes } from "@/router/components/initDoctorRoutes";
import { initPersonRoutes } from "@/router/components/initPersonRoutes";
import { initFilterRoutes } from "@/router/components/initFilterRoutes";
import { initRewardRoutes } from "@/router/components/initRewardRoutes";
import { initClinicRoutes } from "@/router/components/initClinicRoutes";
import { initAuthorRoutes } from "@/router/components/initAuthorRoutes";
import { initFeatureRoutes } from "@/router/objects/initFeatureRoutes";
import { initDataBusRoutes } from "@/router/objects/initDataBusRoutes";
import { initConsentRoutes } from "@/router/objects/initConsentRoutes";
import { initProjectRoutes } from "@/router/objects/initProjectRoutes";
import { initStationRoutes } from "@/router/components/initStationRoutes";
import { initWebinarRoutes } from "@/router/components/initWebinarRoutes";
import { initArticleRoutes } from "@/router/components/initArticleRoutes";
import { initServiceRoutes } from "@/router/components/initServiceRoutes";
import { initComoanyRoutes } from "@/router/components/initCompanyRoutes";
import { initBookletRoutes } from "@/router/components/initBookletRoutes";
import { initCurrencyRoutes } from "@/router/objects/initCurrencyRoutes";
import { initDistrictRoutes } from "@/router/components/initDistrictRoutes";
import { initQuestionRoutes } from "@/router/components/initQuestionRoutes";
import { initDocumentRoutes } from "@/router/components/initDocumentRoutes";
import { initQuotationRoutes } from "@/router/objects/initQuotationRoutes";
import { initStatisticRoutes } from "@/router/components/initStatisticRoutes";
import { initCommodityRoutes } from "@/router/components/initCommodityRoutes";
import { initPriceListRoutes } from "@/router/components/initPriceListRoutes";
import { initFileSystemRoutes } from "@/router/base/initFileSystemRoutes";
import { initInvitationsRoutes } from "@/router/components/initInvitationRoutes";
import { initPromoactionRoutes } from "@/router/components/initPromoactionRoutes";
import { initMailScenarioRoutes } from "@/router/objects/initMailScenarioRoutes";
import { initProductModelRoutes } from "@/router/components/initProductModelRoutes";
import { initProductClassRoutes } from "@/router/components/initProductClassRoutes";
import { initPriceCatalogRoutes } from "@/router/components/initPriceCatalogRoutes";
import { initSecurityGroupRoutes } from "@/router/components/initSecurityGroupRoutes";
import { initEventScheduleRoutes } from "@/router/components/initEventScheduleRoutes";
import { initProductSeriesRoutes } from "@/router/components/initProductSeriesRoutes";
import { initClientWebOrderRoutes } from "@/router/objects/initClientWebOrderRoutes";
import { initEventBroadcastRoutes } from "@/router/components/initEventBroadcastRoutes";
import { initCompanyPartnerRoutes } from "@/router/components/initCompanyPartnerRoutes";
import { initEventSubscriberRoutes } from "@/router/components/initEventSubscriberRoutes";
import { initProfessionalAreaRoutes } from "@/router/components/initProfessionalAreaRoutes";
import { initEventCertificateRoutes } from "@/router/components/initEventCertificateRoutes";
import { initClientCalculationRoutes } from "@/router/objects/initClientCalculationRoutes";
import { initEventRegistrationRoutes } from "@/router/components/initEventRegistrationRoutes";
import { initProductModificationRoutes } from "@/router/components/initProductModificationRoutes";
import { initCertificateTemplateRoutes } from "@/router/components/init-certificate-template-routes";
import { initBookletConfigurationRoutes } from "@/router/components/initBookletConfigurationRoutes";
import { initUserConsentCancellationRoutes } from "@/router/objects/initUserConsentCancellationRoutes";
import {initCourseRoutes} from "@/router/components/initCourseRoutes";



Vue.use(VueRouter);

export const initRoutes = (config: any) => {
  const routes: Array<RouteConfig> = [
    ...initBaseRoutes(config),
    ...initFileSystemRoutes(config),
    ...initArticleRoutes(config),
    ...initNewsRoutes(config),
    ...initProjectRoutes(config, false),
    ...initCrmRoutes(config),
    ...initFormRoutes(config, false),
    ...initFilterRoutes(config, false),
    ...initDocumentRoutes(config),
    ...initCommodityRoutes(config),
    ...initEventRoutes(config),
    ...initEventCertificateRoutes(config),
    ...initCertificateTemplateRoutes(config),
    ...initUserRoutes(config),
    ...initRoleRoutes(config),
    ...initPersonRoutes(config),
    ...initConsentRoutes(config),
    ...initOrderRoutes(config),
    ...initClientWebOrderRoutes(config),
    ...initClientCalculationRoutes(config),
    ...initVideoRoutes(config),
    ...initMailScenarioRoutes(config),
    ...initProductModificationRoutes(config),
    ...initBrandRoutes(config),
    ...initWebinarRoutes(config),
    ...initDataBusRoutes(config),
    ...initEventRegistrationRoutes(config),
    ...initEventScheduleRoutes(config),
    ...initProfessionalAreaRoutes(config),
    ...initEventBroadcastRoutes(config),
    ...initServiceRoutes(config),
    ...initClinicRoutes(config),
    ...initStationRoutes(config),
    ...initFeatureRoutes(config),
    ...initPriceListRoutes(config),
    ...initComoanyRoutes(config),
    ...initDoctorRoutes(config),
    ...initReviewRoutes(config),
    ...initQuestionRoutes(config),
    ...initInvitationsRoutes(config),
    ...initSecurityGroupRoutes(config),
    ...initStatisticRoutes(config),
    ...initDistrictRoutes(config),
    ...initUserConsentCancellationRoutes(config),
    ...initResumeRoutes(config),
    ...initRewardRoutes(config),
    ...initPriceCatalogRoutes(config),
    ...initAuthorRoutes(config),
    ...initPageRoutes(config),
    ...initBookletRoutes(config),
    ...initBookletConfigurationRoutes(config),
    ...initCompanyPartnerRoutes(config),
    ...initPromoactionRoutes(config),
    ...initProductClassRoutes(config),
    ...initProductModelRoutes(config),
    ...initProductSeriesRoutes(config),
    ...initCaseRoutes(config),
    ...initRegionRoutes(config),
    ...initEventSubscriberRoutes(config),
    ...initCurrencyRoutes(config),
    ...initQuotationRoutes(config),
    ...initCourseRoutes(config)
  ];
  const accessRoles = [
    ...new Set(
      routes
        .filter((r: RouteConfig) => !!r.meta?.roles)
        .map((r: RouteConfig) => r.meta?.roles)
        .flat(),
    ),
  ];
  const getUserAccess = (to: Route, isSuccessAuth: boolean): boolean => {
    const roles = to?.meta?.roles ?? accessRoles;
    if (!isSuccessAuth) return false;
    if (roles.length === 0) return true;
    return _.intersection(userModule.roles, roles).length > 0;
  };
  const getNextRoute = (to: Route, hasAccess: boolean): string | undefined => {
    const next = !to?.meta?.requiresAuth || hasAccess ? undefined : "/login/auth";
    return to.name !== "home" && next !== undefined ? "/" : next;
  };

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(async (to: Route, _, next: NavigationGuardNext<Vue>) => {
    const successAuth = await authModule.autoLogin();
    const hasAccess = getUserAccess(to, successAuth);
    let route = getNextRoute(to, hasAccess);
    if (route == undefined) next();
    else next(route);
  });


  return router;
};
